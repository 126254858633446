export default defineNuxtRouteMiddleware(async (to, from) => {


    if (to.matched.length === 0) {
      return navigateTo('/start', { redirectCode: 301 })
    }
    
    const jwtCookie = useCookie('jwt')
    
    if(to.path=='/login') return
    if(to.path.match(/\/brandwall-token/)) return
    
    // go to login if no logged in
    if( !jwtCookie.value){
        localStorage.setItem('lastPath', to.path)
        localStorage.setItem('lastPathQuery', JSON.stringify(to.query))
        return navigateTo({path:'/login'})
    }
    
    // const parsedJWT = parseJWT((jwtCookie||{}).value || '')
    // console.log('parsedJWT', parsedJWT)

    // if(Date.now() > (parsedJWT.expire_time||0)){
    //     if(process.client){
    //         alert('登录已过期，请重新登录')
    //     }
    //     return navigateTo({path:'/login'})
    // }else{
    //     if(to.path == '/'){
    //         return navigateTo({path:'/start'})
    //     }
    // }

})


  