import validate from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45check_45auth_45global from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/middleware/01.checkAuth.global.ts";
import _02_45check_45curr_45org_45global from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/middleware/02.checkCurrOrg.global.ts";
import _03_45redirect_45global from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/middleware/03.redirect.global.ts";
import manifest_45route_45rule from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45check_45auth_45global,
  _02_45check_45curr_45org_45global,
  _03_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}