import { default as jdjyfxjKaJjbHxpOMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/jdjyfx.vue?macro=true";
import { default as ndjyfxqMtpAnspglMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/ndjyfx.vue?macro=true";
import { default as ndjyycqt62hnKb5yMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/ndjyyc.vue?macro=true";
import { default as pgyzw9TlQOld9rrMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/pgyzw.vue?macro=true";
import { default as ydjyfxqpO8RIACRqMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/ydjyfx.vue?macro=true";
import { default as _91dataPage_93TIsLSATU73Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/datapage/[dataPage].vue?macro=true";
import { default as disclaimer2xfsBi54HbMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/docs/disclaimer.vue?macro=true";
import { default as privacyK6mfQWZhGrMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/docs/privacy.vue?macro=true";
import { default as tosthqqntPkqZMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/docs/tos.vue?macro=true";
import { default as companyListG3ARqDjBU4Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/companyList.ts?macro=true";
import { default as hmnbOHcOaULeR7Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/hmnb.vue?macro=true";
import { default as jdesg108J3tuGOaMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/jdesg.vue?macro=true";
import { default as jgjtjdcb_45xq1o2drJR3NgMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/jgjtjdcb-xq.vue?macro=true";
import { default as jgjtjdcbcKg3FJ6kzXMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/jgjtjdcb.vue?macro=true";
import { default as jgjtReportsT5XNMbAVXZMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/jgjtReports.ts?macro=true";
import { default as wlbtjbgA6lrGSSW25Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/wlbtjbg.vue?macro=true";
import { default as _91hotelId_93BJnTnbTehlMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hotel-manage/[hotelId].vue?macro=true";
import { default as hotel_45viewer9ks6Bw6T7FMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hotel-viewer.vue?macro=true";
import { default as jcsjtbTs06SzK5u0Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/jcsjtb.vue?macro=true";
import { default as jdjyfxLyx551rCC5Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/jdjyfx.vue?macro=true";
import { default as ndjyfxhu3gmAcM9PMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/ndjyfx.vue?macro=true";
import { default as ydjyfxsAouvKhKOGMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/ydjyfx.vue?macro=true";
import { default as ydsjtbeNrXohvw6RMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/ydsjtb.vue?macro=true";
import { default as login2xk0hRCHyzMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/login.vue?macro=true";
import { default as pdf_45previewwQolunhPyyMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/pdf-preview.vue?macro=true";
import { default as report_45viewerqHXP4QDxmJMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/report-viewer.vue?macro=true";
import { default as _91sectionName_93tKj6rdqCbFMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/[sectionName].vue?macro=true";
import { default as displayProjectsTdGQsqG2xAMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/displayProjects.ts?macro=true";
import { default as getAdFilesUiYYpEI5m1Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/getAdFiles.ts?macro=true";
import { default as getCategory1Optionstb14B9AzBVMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/getCategory1Options.ts?macro=true";
import { default as getPageContentwNNvlQPqNRMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/getPageContent.ts?macro=true";
import { default as getPageContentOverridemXGyLiGge2Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/getPageContentOverride.ts?macro=true";
import { default as huameiAnnualsB4f0Yjh9xXMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/huameiAnnuals.ts?macro=true";
import { default as processLeftMenuM7xYgCVmzcMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/processLeftMenu.ts?macro=true";
import { default as brands10se65yWjZMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/utils/brands.ts?macro=true";
import { default as citiesZ0Vev8q2vSMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/utils/cities.ts?macro=true";
import { default as jdjyfxuqHQoWuoswMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/shengfen/jdjyfx.vue?macro=true";
import { default as ndjyfxpxOcdThq85Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/shengfen/ndjyfx.vue?macro=true";
import { default as pgyzwOdBzoXwiqJMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/shengfen/pgyzw.vue?macro=true";
import { default as ydjyfxSTt7rSrc7qMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/shengfen/ydjyfx.vue?macro=true";
import { default as indexv1KGLfprA4Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/start/index.vue?macro=true";
import { default as siteConfigJNJ5BU9Ap8Meta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/start/startUtils/siteConfig.ts?macro=true";
import { default as _91organizationId_93WxOqC1cizKMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/user-manage/[organizationId].vue?macro=true";
import { default as bgscn4Cai8ZbKqMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/bgsc.vue?macro=true";
import { default as bgylUBZlMGqHlzMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/bgyl.vue?macro=true";
import { default as report_45taskaePv58piNPMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/report-task.vue?macro=true";
import { default as sampleReportsrBLXrfVrhUMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/sampleReports.ts?macro=true";
import { default as sampleTasksomo8vTVfPXMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/sampleTasks.ts?macro=true";
import { default as ydsjsha2v877G21KMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/ydsjsh.vue?macro=true";
import { default as jdyhglybi7v2TGsNMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xitong/jdyhgl.vue?macro=true";
import { default as qtyhglA71YN47XKEMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xitong/qtyhgl.vue?macro=true";
import { default as xtszCcy9BQnEbhMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xitong/xtsz.vue?macro=true";
import { default as yhglIDZLoBsZeHMeta } from "/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xitong/yhgl.vue?macro=true";
export default [
  {
    name: jdjyfxjKaJjbHxpOMeta?.name ?? "chengshi-jdjyfx",
    path: jdjyfxjKaJjbHxpOMeta?.path ?? "/chengshi/jdjyfx",
    meta: jdjyfxjKaJjbHxpOMeta || {},
    alias: jdjyfxjKaJjbHxpOMeta?.alias || [],
    redirect: jdjyfxjKaJjbHxpOMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/jdjyfx.vue").then(m => m.default || m)
  },
  {
    name: ndjyfxqMtpAnspglMeta?.name ?? "chengshi-ndjyfx",
    path: ndjyfxqMtpAnspglMeta?.path ?? "/chengshi/ndjyfx",
    meta: ndjyfxqMtpAnspglMeta || {},
    alias: ndjyfxqMtpAnspglMeta?.alias || [],
    redirect: ndjyfxqMtpAnspglMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/ndjyfx.vue").then(m => m.default || m)
  },
  {
    name: ndjyycqt62hnKb5yMeta?.name ?? "chengshi-ndjyyc",
    path: ndjyycqt62hnKb5yMeta?.path ?? "/chengshi/ndjyyc",
    meta: ndjyycqt62hnKb5yMeta || {},
    alias: ndjyycqt62hnKb5yMeta?.alias || [],
    redirect: ndjyycqt62hnKb5yMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/ndjyyc.vue").then(m => m.default || m)
  },
  {
    name: pgyzw9TlQOld9rrMeta?.name ?? "chengshi-pgyzw",
    path: pgyzw9TlQOld9rrMeta?.path ?? "/chengshi/pgyzw",
    meta: pgyzw9TlQOld9rrMeta || {},
    alias: pgyzw9TlQOld9rrMeta?.alias || [],
    redirect: pgyzw9TlQOld9rrMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/pgyzw.vue").then(m => m.default || m)
  },
  {
    name: ydjyfxqpO8RIACRqMeta?.name ?? "chengshi-ydjyfx",
    path: ydjyfxqpO8RIACRqMeta?.path ?? "/chengshi/ydjyfx",
    meta: ydjyfxqpO8RIACRqMeta || {},
    alias: ydjyfxqpO8RIACRqMeta?.alias || [],
    redirect: ydjyfxqpO8RIACRqMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/chengshi/ydjyfx.vue").then(m => m.default || m)
  },
  {
    name: _91dataPage_93TIsLSATU73Meta?.name ?? "datapage-dataPage",
    path: _91dataPage_93TIsLSATU73Meta?.path ?? "/datapage/:dataPage()",
    meta: _91dataPage_93TIsLSATU73Meta || {},
    alias: _91dataPage_93TIsLSATU73Meta?.alias || [],
    redirect: _91dataPage_93TIsLSATU73Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/datapage/[dataPage].vue").then(m => m.default || m)
  },
  {
    name: disclaimer2xfsBi54HbMeta?.name ?? "docs-disclaimer",
    path: disclaimer2xfsBi54HbMeta?.path ?? "/docs/disclaimer",
    meta: disclaimer2xfsBi54HbMeta || {},
    alias: disclaimer2xfsBi54HbMeta?.alias || [],
    redirect: disclaimer2xfsBi54HbMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/docs/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: privacyK6mfQWZhGrMeta?.name ?? "docs-privacy",
    path: privacyK6mfQWZhGrMeta?.path ?? "/docs/privacy",
    meta: privacyK6mfQWZhGrMeta || {},
    alias: privacyK6mfQWZhGrMeta?.alias || [],
    redirect: privacyK6mfQWZhGrMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/docs/privacy.vue").then(m => m.default || m)
  },
  {
    name: tosthqqntPkqZMeta?.name ?? "docs-tos",
    path: tosthqqntPkqZMeta?.path ?? "/docs/tos",
    meta: tosthqqntPkqZMeta || {},
    alias: tosthqqntPkqZMeta?.alias || [],
    redirect: tosthqqntPkqZMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/docs/tos.vue").then(m => m.default || m)
  },
  {
    name: companyListG3ARqDjBU4Meta?.name ?? "hangye-companyList",
    path: companyListG3ARqDjBU4Meta?.path ?? "/hangye/companyList",
    meta: companyListG3ARqDjBU4Meta || {},
    alias: companyListG3ARqDjBU4Meta?.alias || [],
    redirect: companyListG3ARqDjBU4Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/companyList.ts").then(m => m.default || m)
  },
  {
    name: hmnbOHcOaULeR7Meta?.name ?? "hangye-hmnb",
    path: hmnbOHcOaULeR7Meta?.path ?? "/hangye/hmnb",
    meta: hmnbOHcOaULeR7Meta || {},
    alias: hmnbOHcOaULeR7Meta?.alias || [],
    redirect: hmnbOHcOaULeR7Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/hmnb.vue").then(m => m.default || m)
  },
  {
    name: jdesg108J3tuGOaMeta?.name ?? "hangye-jdesg",
    path: jdesg108J3tuGOaMeta?.path ?? "/hangye/jdesg",
    meta: jdesg108J3tuGOaMeta || {},
    alias: jdesg108J3tuGOaMeta?.alias || [],
    redirect: jdesg108J3tuGOaMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/jdesg.vue").then(m => m.default || m)
  },
  {
    name: jgjtjdcb_45xq1o2drJR3NgMeta?.name ?? "hangye-jgjtjdcb-xq",
    path: jgjtjdcb_45xq1o2drJR3NgMeta?.path ?? "/hangye/jgjtjdcb-xq",
    meta: jgjtjdcb_45xq1o2drJR3NgMeta || {},
    alias: jgjtjdcb_45xq1o2drJR3NgMeta?.alias || [],
    redirect: jgjtjdcb_45xq1o2drJR3NgMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/jgjtjdcb-xq.vue").then(m => m.default || m)
  },
  {
    name: jgjtjdcbcKg3FJ6kzXMeta?.name ?? "hangye-jgjtjdcb",
    path: jgjtjdcbcKg3FJ6kzXMeta?.path ?? "/hangye/jgjtjdcb",
    meta: jgjtjdcbcKg3FJ6kzXMeta || {},
    alias: jgjtjdcbcKg3FJ6kzXMeta?.alias || [],
    redirect: jgjtjdcbcKg3FJ6kzXMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/jgjtjdcb.vue").then(m => m.default || m)
  },
  {
    name: jgjtReportsT5XNMbAVXZMeta?.name ?? "hangye-jgjtReports",
    path: jgjtReportsT5XNMbAVXZMeta?.path ?? "/hangye/jgjtReports",
    meta: jgjtReportsT5XNMbAVXZMeta || {},
    alias: jgjtReportsT5XNMbAVXZMeta?.alias || [],
    redirect: jgjtReportsT5XNMbAVXZMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/jgjtReports.ts").then(m => m.default || m)
  },
  {
    name: wlbtjbgA6lrGSSW25Meta?.name ?? "hangye-wlbtjbg",
    path: wlbtjbgA6lrGSSW25Meta?.path ?? "/hangye/wlbtjbg",
    meta: wlbtjbgA6lrGSSW25Meta || {},
    alias: wlbtjbgA6lrGSSW25Meta?.alias || [],
    redirect: wlbtjbgA6lrGSSW25Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hangye/wlbtjbg.vue").then(m => m.default || m)
  },
  {
    name: _91hotelId_93BJnTnbTehlMeta?.name ?? "hotel-manage-hotelId",
    path: _91hotelId_93BJnTnbTehlMeta?.path ?? "/hotel-manage/:hotelId()",
    meta: _91hotelId_93BJnTnbTehlMeta || {},
    alias: _91hotelId_93BJnTnbTehlMeta?.alias || [],
    redirect: _91hotelId_93BJnTnbTehlMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hotel-manage/[hotelId].vue").then(m => m.default || m)
  },
  {
    name: hotel_45viewer9ks6Bw6T7FMeta?.name ?? "hotel-viewer",
    path: hotel_45viewer9ks6Bw6T7FMeta?.path ?? "/hotel-viewer",
    meta: hotel_45viewer9ks6Bw6T7FMeta || {},
    alias: hotel_45viewer9ks6Bw6T7FMeta?.alias || [],
    redirect: hotel_45viewer9ks6Bw6T7FMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/hotel-viewer.vue").then(m => m.default || m)
  },
  {
    name: jcsjtbTs06SzK5u0Meta?.name ?? "jiudian-jcsjtb",
    path: jcsjtbTs06SzK5u0Meta?.path ?? "/jiudian/jcsjtb",
    meta: jcsjtbTs06SzK5u0Meta || {},
    alias: jcsjtbTs06SzK5u0Meta?.alias || [],
    redirect: jcsjtbTs06SzK5u0Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/jcsjtb.vue").then(m => m.default || m)
  },
  {
    name: jdjyfxLyx551rCC5Meta?.name ?? "jiudian-jdjyfx",
    path: jdjyfxLyx551rCC5Meta?.path ?? "/jiudian/jdjyfx",
    meta: jdjyfxLyx551rCC5Meta || {},
    alias: jdjyfxLyx551rCC5Meta?.alias || [],
    redirect: jdjyfxLyx551rCC5Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/jdjyfx.vue").then(m => m.default || m)
  },
  {
    name: ndjyfxhu3gmAcM9PMeta?.name ?? "jiudian-ndjyfx",
    path: ndjyfxhu3gmAcM9PMeta?.path ?? "/jiudian/ndjyfx",
    meta: ndjyfxhu3gmAcM9PMeta || {},
    alias: ndjyfxhu3gmAcM9PMeta?.alias || [],
    redirect: ndjyfxhu3gmAcM9PMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/ndjyfx.vue").then(m => m.default || m)
  },
  {
    name: ydjyfxsAouvKhKOGMeta?.name ?? "jiudian-ydjyfx",
    path: ydjyfxsAouvKhKOGMeta?.path ?? "/jiudian/ydjyfx",
    meta: ydjyfxsAouvKhKOGMeta || {},
    alias: ydjyfxsAouvKhKOGMeta?.alias || [],
    redirect: ydjyfxsAouvKhKOGMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/ydjyfx.vue").then(m => m.default || m)
  },
  {
    name: ydsjtbeNrXohvw6RMeta?.name ?? "jiudian-ydsjtb",
    path: ydsjtbeNrXohvw6RMeta?.path ?? "/jiudian/ydsjtb",
    meta: ydsjtbeNrXohvw6RMeta || {},
    alias: ydsjtbeNrXohvw6RMeta?.alias || [],
    redirect: ydsjtbeNrXohvw6RMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/jiudian/ydsjtb.vue").then(m => m.default || m)
  },
  {
    name: login2xk0hRCHyzMeta?.name ?? "login",
    path: login2xk0hRCHyzMeta?.path ?? "/login",
    meta: login2xk0hRCHyzMeta || {},
    alias: login2xk0hRCHyzMeta?.alias || [],
    redirect: login2xk0hRCHyzMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: pdf_45previewwQolunhPyyMeta?.name ?? "pdf-preview",
    path: pdf_45previewwQolunhPyyMeta?.path ?? "/pdf-preview",
    meta: pdf_45previewwQolunhPyyMeta || {},
    alias: pdf_45previewwQolunhPyyMeta?.alias || [],
    redirect: pdf_45previewwQolunhPyyMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/pdf-preview.vue").then(m => m.default || m)
  },
  {
    name: report_45viewerqHXP4QDxmJMeta?.name ?? "report-viewer",
    path: report_45viewerqHXP4QDxmJMeta?.path ?? "/report-viewer",
    meta: report_45viewerqHXP4QDxmJMeta || {},
    alias: report_45viewerqHXP4QDxmJMeta?.alias || [],
    redirect: report_45viewerqHXP4QDxmJMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/report-viewer.vue").then(m => m.default || m)
  },
  {
    name: _91sectionName_93tKj6rdqCbFMeta?.name ?? "section-sectionName",
    path: _91sectionName_93tKj6rdqCbFMeta?.path ?? "/section/:sectionName()",
    meta: _91sectionName_93tKj6rdqCbFMeta || {},
    alias: _91sectionName_93tKj6rdqCbFMeta?.alias || [],
    redirect: _91sectionName_93tKj6rdqCbFMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/[sectionName].vue").then(m => m.default || m)
  },
  {
    name: displayProjectsTdGQsqG2xAMeta?.name ?? "section-sectionUtils-displayProjects",
    path: displayProjectsTdGQsqG2xAMeta?.path ?? "/section/sectionUtils/displayProjects",
    meta: displayProjectsTdGQsqG2xAMeta || {},
    alias: displayProjectsTdGQsqG2xAMeta?.alias || [],
    redirect: displayProjectsTdGQsqG2xAMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/displayProjects.ts").then(m => m.default || m)
  },
  {
    name: getAdFilesUiYYpEI5m1Meta?.name ?? "section-sectionUtils-getAdFiles",
    path: getAdFilesUiYYpEI5m1Meta?.path ?? "/section/sectionUtils/getAdFiles",
    meta: getAdFilesUiYYpEI5m1Meta || {},
    alias: getAdFilesUiYYpEI5m1Meta?.alias || [],
    redirect: getAdFilesUiYYpEI5m1Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/getAdFiles.ts").then(m => m.default || m)
  },
  {
    name: getCategory1Optionstb14B9AzBVMeta?.name ?? "section-sectionUtils-getCategory1Options",
    path: getCategory1Optionstb14B9AzBVMeta?.path ?? "/section/sectionUtils/getCategory1Options",
    meta: getCategory1Optionstb14B9AzBVMeta || {},
    alias: getCategory1Optionstb14B9AzBVMeta?.alias || [],
    redirect: getCategory1Optionstb14B9AzBVMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/getCategory1Options.ts").then(m => m.default || m)
  },
  {
    name: getPageContentwNNvlQPqNRMeta?.name ?? "section-sectionUtils-getPageContent",
    path: getPageContentwNNvlQPqNRMeta?.path ?? "/section/sectionUtils/getPageContent",
    meta: getPageContentwNNvlQPqNRMeta || {},
    alias: getPageContentwNNvlQPqNRMeta?.alias || [],
    redirect: getPageContentwNNvlQPqNRMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/getPageContent.ts").then(m => m.default || m)
  },
  {
    name: getPageContentOverridemXGyLiGge2Meta?.name ?? "section-sectionUtils-getPageContentOverride",
    path: getPageContentOverridemXGyLiGge2Meta?.path ?? "/section/sectionUtils/getPageContentOverride",
    meta: getPageContentOverridemXGyLiGge2Meta || {},
    alias: getPageContentOverridemXGyLiGge2Meta?.alias || [],
    redirect: getPageContentOverridemXGyLiGge2Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/getPageContentOverride.ts").then(m => m.default || m)
  },
  {
    name: huameiAnnualsB4f0Yjh9xXMeta?.name ?? "section-sectionUtils-huameiAnnuals",
    path: huameiAnnualsB4f0Yjh9xXMeta?.path ?? "/section/sectionUtils/huameiAnnuals",
    meta: huameiAnnualsB4f0Yjh9xXMeta || {},
    alias: huameiAnnualsB4f0Yjh9xXMeta?.alias || [],
    redirect: huameiAnnualsB4f0Yjh9xXMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/huameiAnnuals.ts").then(m => m.default || m)
  },
  {
    name: processLeftMenuM7xYgCVmzcMeta?.name ?? "section-sectionUtils-processLeftMenu",
    path: processLeftMenuM7xYgCVmzcMeta?.path ?? "/section/sectionUtils/processLeftMenu",
    meta: processLeftMenuM7xYgCVmzcMeta || {},
    alias: processLeftMenuM7xYgCVmzcMeta?.alias || [],
    redirect: processLeftMenuM7xYgCVmzcMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/processLeftMenu.ts").then(m => m.default || m)
  },
  {
    name: brands10se65yWjZMeta?.name ?? "section-sectionUtils-utils-brands",
    path: brands10se65yWjZMeta?.path ?? "/section/sectionUtils/utils/brands",
    meta: brands10se65yWjZMeta || {},
    alias: brands10se65yWjZMeta?.alias || [],
    redirect: brands10se65yWjZMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/utils/brands.ts").then(m => m.default || m)
  },
  {
    name: citiesZ0Vev8q2vSMeta?.name ?? "section-sectionUtils-utils-cities",
    path: citiesZ0Vev8q2vSMeta?.path ?? "/section/sectionUtils/utils/cities",
    meta: citiesZ0Vev8q2vSMeta || {},
    alias: citiesZ0Vev8q2vSMeta?.alias || [],
    redirect: citiesZ0Vev8q2vSMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/section/sectionUtils/utils/cities.ts").then(m => m.default || m)
  },
  {
    name: jdjyfxuqHQoWuoswMeta?.name ?? "shengfen-jdjyfx",
    path: jdjyfxuqHQoWuoswMeta?.path ?? "/shengfen/jdjyfx",
    meta: jdjyfxuqHQoWuoswMeta || {},
    alias: jdjyfxuqHQoWuoswMeta?.alias || [],
    redirect: jdjyfxuqHQoWuoswMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/shengfen/jdjyfx.vue").then(m => m.default || m)
  },
  {
    name: ndjyfxpxOcdThq85Meta?.name ?? "shengfen-ndjyfx",
    path: ndjyfxpxOcdThq85Meta?.path ?? "/shengfen/ndjyfx",
    meta: ndjyfxpxOcdThq85Meta || {},
    alias: ndjyfxpxOcdThq85Meta?.alias || [],
    redirect: ndjyfxpxOcdThq85Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/shengfen/ndjyfx.vue").then(m => m.default || m)
  },
  {
    name: pgyzwOdBzoXwiqJMeta?.name ?? "shengfen-pgyzw",
    path: pgyzwOdBzoXwiqJMeta?.path ?? "/shengfen/pgyzw",
    meta: pgyzwOdBzoXwiqJMeta || {},
    alias: pgyzwOdBzoXwiqJMeta?.alias || [],
    redirect: pgyzwOdBzoXwiqJMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/shengfen/pgyzw.vue").then(m => m.default || m)
  },
  {
    name: ydjyfxSTt7rSrc7qMeta?.name ?? "shengfen-ydjyfx",
    path: ydjyfxSTt7rSrc7qMeta?.path ?? "/shengfen/ydjyfx",
    meta: ydjyfxSTt7rSrc7qMeta || {},
    alias: ydjyfxSTt7rSrc7qMeta?.alias || [],
    redirect: ydjyfxSTt7rSrc7qMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/shengfen/ydjyfx.vue").then(m => m.default || m)
  },
  {
    name: indexv1KGLfprA4Meta?.name ?? "start",
    path: indexv1KGLfprA4Meta?.path ?? "/start",
    meta: indexv1KGLfprA4Meta || {},
    alias: indexv1KGLfprA4Meta?.alias || [],
    redirect: indexv1KGLfprA4Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/start/index.vue").then(m => m.default || m)
  },
  {
    name: siteConfigJNJ5BU9Ap8Meta?.name ?? "start-startUtils-siteConfig",
    path: siteConfigJNJ5BU9Ap8Meta?.path ?? "/start/startUtils/siteConfig",
    meta: siteConfigJNJ5BU9Ap8Meta || {},
    alias: siteConfigJNJ5BU9Ap8Meta?.alias || [],
    redirect: siteConfigJNJ5BU9Ap8Meta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/start/startUtils/siteConfig.ts").then(m => m.default || m)
  },
  {
    name: _91organizationId_93WxOqC1cizKMeta?.name ?? "user-manage-organizationId",
    path: _91organizationId_93WxOqC1cizKMeta?.path ?? "/user-manage/:organizationId()",
    meta: _91organizationId_93WxOqC1cizKMeta || {},
    alias: _91organizationId_93WxOqC1cizKMeta?.alias || [],
    redirect: _91organizationId_93WxOqC1cizKMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/user-manage/[organizationId].vue").then(m => m.default || m)
  },
  {
    name: bgscn4Cai8ZbKqMeta?.name ?? "xiehui-bgsc",
    path: bgscn4Cai8ZbKqMeta?.path ?? "/xiehui/bgsc",
    meta: bgscn4Cai8ZbKqMeta || {},
    alias: bgscn4Cai8ZbKqMeta?.alias || [],
    redirect: bgscn4Cai8ZbKqMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/bgsc.vue").then(m => m.default || m)
  },
  {
    name: bgylUBZlMGqHlzMeta?.name ?? "xiehui-bgyl",
    path: bgylUBZlMGqHlzMeta?.path ?? "/xiehui/bgyl",
    meta: bgylUBZlMGqHlzMeta || {},
    alias: bgylUBZlMGqHlzMeta?.alias || [],
    redirect: bgylUBZlMGqHlzMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/bgyl.vue").then(m => m.default || m)
  },
  {
    name: report_45taskaePv58piNPMeta?.name ?? "xiehui-report-task",
    path: report_45taskaePv58piNPMeta?.path ?? "/xiehui/report-task",
    meta: report_45taskaePv58piNPMeta || {},
    alias: report_45taskaePv58piNPMeta?.alias || [],
    redirect: report_45taskaePv58piNPMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/report-task.vue").then(m => m.default || m)
  },
  {
    name: sampleReportsrBLXrfVrhUMeta?.name ?? "xiehui-sampleReports",
    path: sampleReportsrBLXrfVrhUMeta?.path ?? "/xiehui/sampleReports",
    meta: sampleReportsrBLXrfVrhUMeta || {},
    alias: sampleReportsrBLXrfVrhUMeta?.alias || [],
    redirect: sampleReportsrBLXrfVrhUMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/sampleReports.ts").then(m => m.default || m)
  },
  {
    name: sampleTasksomo8vTVfPXMeta?.name ?? "xiehui-sampleTasks",
    path: sampleTasksomo8vTVfPXMeta?.path ?? "/xiehui/sampleTasks",
    meta: sampleTasksomo8vTVfPXMeta || {},
    alias: sampleTasksomo8vTVfPXMeta?.alias || [],
    redirect: sampleTasksomo8vTVfPXMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/sampleTasks.ts").then(m => m.default || m)
  },
  {
    name: ydsjsha2v877G21KMeta?.name ?? "xiehui-ydsjsh",
    path: ydsjsha2v877G21KMeta?.path ?? "/xiehui/ydsjsh",
    meta: ydsjsha2v877G21KMeta || {},
    alias: ydsjsha2v877G21KMeta?.alias || [],
    redirect: ydsjsha2v877G21KMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xiehui/ydsjsh.vue").then(m => m.default || m)
  },
  {
    name: jdyhglybi7v2TGsNMeta?.name ?? "xitong-jdyhgl",
    path: jdyhglybi7v2TGsNMeta?.path ?? "/xitong/jdyhgl",
    meta: jdyhglybi7v2TGsNMeta || {},
    alias: jdyhglybi7v2TGsNMeta?.alias || [],
    redirect: jdyhglybi7v2TGsNMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xitong/jdyhgl.vue").then(m => m.default || m)
  },
  {
    name: qtyhglA71YN47XKEMeta?.name ?? "xitong-qtyhgl",
    path: qtyhglA71YN47XKEMeta?.path ?? "/xitong/qtyhgl",
    meta: qtyhglA71YN47XKEMeta || {},
    alias: qtyhglA71YN47XKEMeta?.alias || [],
    redirect: qtyhglA71YN47XKEMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xitong/qtyhgl.vue").then(m => m.default || m)
  },
  {
    name: xtszCcy9BQnEbhMeta?.name ?? "xitong-xtsz",
    path: xtszCcy9BQnEbhMeta?.path ?? "/xitong/xtsz",
    meta: xtszCcy9BQnEbhMeta || {},
    alias: xtszCcy9BQnEbhMeta?.alias || [],
    redirect: xtszCcy9BQnEbhMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xitong/xtsz.vue").then(m => m.default || m)
  },
  {
    name: yhglIDZLoBsZeHMeta?.name ?? "xitong-yhgl",
    path: yhglIDZLoBsZeHMeta?.path ?? "/xitong/yhgl",
    meta: yhglIDZLoBsZeHMeta || {},
    alias: yhglIDZLoBsZeHMeta?.alias || [],
    redirect: yhglIDZLoBsZeHMeta?.redirect,
    component: () => import("/Volumes/Tian_SanDisk/DEV/datawang/frontend/pages/xitong/yhgl.vue").then(m => m.default || m)
  }
]