import {getMenuLeaf} from '@/controllers/menus'

const stapleItems = [
    /[0-9]+\.项目\/[0-9]+\.相关信息/,
    /[0-9]+\.项目\/[0-9]+\.华美服务/,
    /[0-9]+\.城市\/.+\/[0-9]+\.查酒店/,
    /[0-9]+\.城市\/.+\/[0-9]+\.华美服务@/,
]

const projectStapleItems = {
    "增量项目":[
        /[0-9]+\.项目\/[0-9]+\.项目管理\/[0-9]+\.项目时间线/,
    ]
}

const checkStaple = (path: string, projectType:string) => {
    for(const item of stapleItems){

        if(path.match(item)){
            return true
        }
    }
    if(projectType && projectStapleItems[projectType]){
        for(const item of projectStapleItems[projectType]){
            if(path.match(item)){
                return true
            }
        }
    }
    return false
}

const getProjectType = (project:any) => {
    if(!project){
        return ""
    }
    const projectTypeKey = Object.keys(project)
                        .find(key => key.includes('项目类型'))

    if(!projectTypeKey){
        return ""
    }
    return project[projectTypeKey]
}



export const processLeftMenu = async (
    rawData: DingFileDriveFile[],
    category1: string,
    currInstance: string,
    currProject: any,
    userAuth: any,
    isProd: boolean,
) => {

    const out = [] as DingFileDriveFile[]
    
    const projectType = getProjectType(currProject)
    
    for(const item of rawData){
        
        const availablePaths = [] as string[]

        const promises = [] as Promise<any>[]
        for(const child of item.children){

            if(child.name.match(/^【/)){
                continue
            }

            // 跳过无权限
            if(!["打开","试用"].includes(userAuth[`【权】${category1}/${child.display}`])){
                // if(child.path.includes("相关信息")){
                //     console.log(
                //         '相关信息1', 
                //         `【权】${category1}/${child.display}`, 
                //         userAuth[`【权】${category1}/${child.display}`] 
                //     )
                // }
                continue
            }

            // 保留固定项
            if(checkStaple(child.path, projectType)){
                availablePaths.push(child.path)
                continue
            }

            promises.push(getMenuLeaf(child.path).then((res)=>{
                if(
                    ["项目", "城市", "品牌"].includes(category1)
                    && !(
                        child.path.includes("共建主页")
                    )
                ){
                    if(
                        res.children.find(x=>x.display.includes(currInstance))
                    ){
                        availablePaths.push(child.path)
                    }
                }else{
                    if (res.children.length){
                        availablePaths.push(child.path)
                    }
                }
            }).catch((err)=>{
                console.log('initMenuSwitches ERR', err)
            }))
        }

        await Promise.all(promises)
        
        const childrenWithHiddenSet = item.children.map(x=>({
            ...x,
            hidden: !availablePaths.includes(x.path)
        })).filter(x=>(!x.hidden) || (!isProd))

        if(
            (availablePaths.length > 0)
            || (!isProd)
        ){
            out.push({
                ...item,
                hidden: availablePaths.length == 0,
                children: processChildren(
                    childrenWithHiddenSet,
                    category1,
                    currInstance,
                    currProject,
                )
            })
        }
    }
    return out
}


const processChildren = (
    menu: DingFileDriveFile[],
    category1: string,
    currInstance: string,
    currProject: any,
) => {
    const instances = {
        "城市":[],
        "品牌":[],
    }
    if(category1 == "城市"){
        instances["城市"] = [currInstance]
    }
    if(category1 == "品牌"){
        instances["品牌"] = [currInstance]
    }
    if(
        category1 == "项目"
        && currProject
    ){
        const cityKey = Object.keys(currProject).find(x=>x.match(/项目城市/))
        const brandKey = Object.keys(currProject).find(x=>x.match(/项目品牌/))

        instances["城市"] = (currProject[cityKey]||"").split(',')
        instances["品牌"] = (currProject[brandKey]||"").split(',')
    }
    const out = []
    for(const item of menu){

        // originalDisplay 用于记录原始显示名称，例如 华美服务@【城市名】
        item.originalDisplay = item.display

        if(item.display.match(/【城市名】$/)){
            for(const instance of instances["城市"]){
                if(!instance) continue
                out.push({
                    ...item,
                    hidden:false,
                    display: item.display.replace(/【城市名】$/, instance),
                    path: item.display.match(/城市主页/) ? `内容文件/2.城市` : item.path,
                    adType: "城市",
                    adInstance: instance,
                    queries: {
                        instance
                    }
                })
            }
        }else if(item.display.match(/【品牌名】$/)){
            for(const instance of instances["品牌"]){
                if(!instance) continue
                out.push({
                    ...item,
                    hidden:false,
                    display: item.display.replace(/【品牌名】$/, instance),
                    path: item.display.match(/品牌主页/) ? `内容文件/3.品牌` : item.path,
                    adType: "品牌",
                    adInstance: instance,
                    queries: {
                        instance
                    }
                })
            }
        }else{
            out.push(item)
        }
    }
    return out
    
}